.Pointer {
    cursor: pointer;
}

.overflow{
    max-width:200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
